define("@ss/ui/components/ui/actions-menu", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isMobile}}
    <div class="ui-actions-menu--mobile" ...attributes>
      <Ui::Dropdown @onOpen={{@onOpen}} as |dd|>
        <dd.trigger class={{@triggerClassNames}} @stopPropagation={{true}}>
          <Ui::IconButton
            @icon="more_vert"
            @modifier="primary-square"
            class="icon-button--small {{@triggerButtonClassNames}}"
          />
        </dd.trigger>
  
        <dd.content>
          {{yield (hash item=dd.item link=dd.link actions=dd.actions isMobile=true)}}
        </dd.content>
      </Ui::Dropdown>
    </div>
  {{else}}
    <div class="secondary-action-buttons" ...attributes>
      {{yield
        (hash item=(component "ui/actions-menu/icon-button") link=(component "ui/actions-menu/icon-link") isMobile=false)
      }}
    </div>
  {{/if}}
  */
  {
    "id": "gc/XoA4G",
    "block": "[[[41,[30,0,[\"isMobile\"]],[[[1,\"  \"],[11,0],[24,0,\"ui-actions-menu--mobile\"],[17,1],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@onOpen\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,3,[\"trigger\"]],[[16,0,[30,4]]],[[\"@stopPropagation\"],[true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],[[16,0,[29,[\"icon-button--small \",[30,5]]]]],[[\"@icon\",\"@modifier\"],[\"more_vert\",\"primary-square\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[30,3,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[18,6,[[28,[37,4],null,[[\"item\",\"link\",\"actions\",\"isMobile\"],[[30,3,[\"item\"]],[30,3,[\"link\"]],[30,3,[\"actions\"]],true]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[24,0,\"secondary-action-buttons\"],[17,1],[12],[1,\"\\n    \"],[18,6,[[28,[37,4],null,[[\"item\",\"link\",\"isMobile\"],[[50,\"ui/actions-menu/icon-button\",0,null,null],[50,\"ui/actions-menu/icon-link\",0,null,null],false]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&attrs\",\"@onOpen\",\"dd\",\"@triggerClassNames\",\"@triggerButtonClassNames\",\"&default\"],false,[\"if\",\"ui/dropdown\",\"ui/icon-button\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@ss/ui/components/ui/actions-menu.hbs",
    "isStrictMode": false
  });
  let UiActionsMenuComponent = (_class = class UiActionsMenuComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "screen", _descriptor, this);
    }
    get mobileBreakpoint() {
      return this.args.mobileBreakpoint ?? 940;
    }
    get isMobile() {
      return this.args.forceMobile ?? this.screen.width <= this.mobileBreakpoint;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "screen", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = UiActionsMenuComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiActionsMenuComponent);
});